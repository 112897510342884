@import url(https://use.typekit.net/ihm4eod.css);
@import url(https://use.typekit.net/ihm4eod.css);
.MuiBackdrop-root{z-index:20 !important}.MuiGrid-spacing-xs-5{margin:0 !important}.MuiButton-root{border-radius:0 !important}.MuiTypography-body1{font-size:.9em !important}#root{position:relative;min-height:100vh}body{margin:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}.MuiAppBar-root{margin-bottom:4em}*,::after,::before{box-sizing:border-box}h1,h2,h3,h4,h5,h6,p,a,span,div,li{font-weight:300;font-family:proxima-nova,sans-serif !important;font-style:normal !important}h1,h2,h3,h4,h5,h6{color:#000;margin-top:0 !important;margin-bottom:.5rem !important}h1,h2{color:#2e2f30;font-size:20px !important}h3{font-size:22px !important}h4{font-weight:bold !important;font-size:.95em !important}h5{color:#2e2f30;font-size:.8em !important}h6{font-size:14px !important;color:rgba(46,47,48,.6)}p{margin-top:0 !important;margin-bottom:0 !important;font-size:.9em !important}hr{margin-top:1rem !important;margin-bottom:1rem !important}input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}.no-wrap{white-space:nowrap}
.amount-form{border:1px solid #01052c}.amount-form button{padding-right:0px;padding-left:0px}.amount-form .MuiTextField-root{max-width:3.5rem;margin:0px}.amount-form .MuiTextField-root .MuiOutlinedInput-root fieldset{border-top:0px;border-bottom:0px;border-radius:0px}.amount-form .MuiTextField-root .MuiOutlinedInput-root .MuiInputBase-input{padding:8px}.amount-form input{text-align:center}.amount-form fieldset{border-width:0px !important;text-align:right}.amount-form .MuiButton-root{min-width:2.5em !important}
.footer-wrap{padding-bottom:2.5rem}.footer-wrap .footer{width:100%;bottom:0;position:absolute;height:2.5rem}.footer-wrap .footer .container{margin:auto}.footer-wrap .footer .details-container{margin-bottom:40px !important}.footer-wrap .footer .trademark{margin:32px auto !important}.footer-wrap .footer h1,.footer-wrap .footer h2,.footer-wrap .footer h3,.footer-wrap .footer h4,.footer-wrap .footer h5,.footer-wrap .footer h6,.footer-wrap .footer p,.footer-wrap .footer a{font-family:brandon-grotesque,sans-serif !important}
.navbar{margin-top:.5em}@media(max-width: 768px){.navbar a{text-align:left !important}.navbar .brand{width:60px;object-fit:cover;object-position:0 100%}}.navbar .brand{height:2.5em}.navbar .collapse{justify-content:flex-end}.navbar .collapse a{width:7em;height:3em;text-align:center;vertical-align:middle;display:table-cell}.navbar .collapse .nav-item-icon{width:3em !important}.navbar h1,.navbar h2,.navbar h3,.navbar h4,.navbar h5,.navbar h6,.navbar p,.navbar a{font-weight:400 !important;font-family:brandon-grotesque,sans-serif !important}
.page-title{text-transform:uppercase;width:100%;text-align:center;margin:1.2em 0px 2.3em !important;position:relative;z-index:1;color:#a61c32 !important}.page-title::before,.page-title::after{position:absolute;top:51%;overflow:hidden;width:50%;height:1px;content:" ";color:"rgba(0; 0; 0; 0)";background-color:#01052c}.page-title::before{margin-right:10px;margin-left:-50%;text-align:right}
.product-card .card{border:0;cursor:pointer}.product-card .card .media{height:194px;width:194px;margin:auto}
.spacer{margin-top:2em}
.product-view .tabs-container{margin:3em auto 6em auto;min-height:20em}.product-view .description{height:72px;line-height:24px;overflow:hidden}.product-view h2{margin-top:2em !important;color:#a61c32;font-size:1.5em !important;letter-spacing:.25px !important;font-weight:700 !important}
.product-image .active:after{transition:width .5s ease,background-color .5s ease !important;width:100% !important;background:#01052c !important}.product-image .small-image-container{overflow:hidden}.product-image .small-image-container img{width:100%;height:100%}.product-image .small-image-container:after{content:"";display:block;margin:auto;height:.01em;width:0px;background:rgba(0,0,0,0);transition:width .5s ease,background-color .5s ease}.product-image .small-image-container:hover:after{width:100%;background:#01052c}.product-image .magnified-image{overflow:hidden;border-radius:.375rem;box-shadow:0 2px 5px 0 rgba(0,0,0,.07);height:444px;width:444px;margin:5px auto;text-align:center;display:flex}.product-image .magnified-image img{transform-origin:center center;margin:0 auto;max-height:100%;max-width:100%;cursor:zoom-in}
.filter-amount{color:rgba(46,47,48,.6)}@media(max-width: 599px){.product-search{display:none !important}}
.cart-page{margin-bottom:100px}.cart-page img{max-width:100px}.cart-page .contents{min-height:300px;padding:25px 0px;border-bottom:1px solid rgba(46,47,48,.2);border-top:1px solid rgba(46,47,48,.2);margin-bottom:20px}.cart-page .sum-table,.cart-page .format-order{float:right;width:400px}.cart-page button{border:0px}.cart-page td{white-space:nowrap}
