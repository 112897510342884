.product-card {
  .card {
    border: 0;
    cursor: pointer;

    .media {
      height: 194px;
      width: 194px;
      margin: auto;
    }
  }
}
