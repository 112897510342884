@import '../../../../common/components/colors.scss';

.product-image {
  .active {
    &:after {
      transition: width .5s ease, background-color .5s ease !important;
      width: 100% !important;
      background: $primary-color !important;
    }
  }

  .small-image-container {
  	overflow: hidden;

		img {
			width: 100%;
			height: 100%;
		}

    &:after {
      content: '';
      display: block;
      margin: auto;
      height: 0.01em;
      width: 0px;
      background: transparent;
      transition: width .5s ease, background-color .5s ease;
    }

    &:hover:after {
      width: 100%;
      background: $primary-color;
    }
  }

  .magnified-image {
    overflow: hidden;
    border-radius: 0.375rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.07);
    height: 444px;
    width: 444px;
    margin: 5px auto;
    text-align: center;
    display: flex;

    img {
      transform-origin: center center;
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
      cursor: zoom-in;
    }
  }
}
