.MuiBackdrop-root {
  z-index: 20 !important;
}

.MuiGrid-spacing-xs-5 {
	margin: 0 !important;
}

.MuiButton-root {
	border-radius: 0 !important;
}

.MuiTypography-body1 {
	font-size: 0.9em !important;
}
