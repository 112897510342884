@import url("https://use.typekit.net/ihm4eod.css");
@import './modify-material-ui.scss';

@import './common/components/colors.scss';

#root {
  position: relative;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiAppBar-root {
  margin-bottom: 4em;
}

*, ::after, ::before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, a, span, div, li {
  font-weight: 300;
  font-family: proxima-nova, sans-serif !important;
  font-style: normal !important;
}

h1, h2, h3, h4, h5, h6 {
  color: $black-color;
  margin-top: 0 !important;
  margin-bottom: .5rem !important;
}

h1, h2 {
  color: $grey-dark-color;
  font-size: 20px !important;
}

h3 {
  font-size: 22px !important;
}

h4 {
  font-weight: bold !important;
  font-size: .95em !important;
}

h5 {
  color: $grey-dark-color;
  font-size: 0.8em !important;
}

h6 {
  font-size: 14px !important;
  color: rgba($grey-dark-color, 0.6)
}

p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 0.9em !important;
}

hr {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.no-wrap {
	white-space: nowrap;
}
