.navbar {
  margin-top: 0.5em;


  @media (max-width: 768px) {
    a {
      text-align: left !important;
    }

    .brand {
      width: 60px;
      object-fit: cover;
      object-position: 0 100%;
    }
  }

  .brand {
      height: 2.5em;
  }

  .collapse {
    justify-content: flex-end;

    a {
      width: 7em;
      height: 3em;
      text-align: center;
      vertical-align: middle;
      display: table-cell;
    }


    .nav-item-icon {
      width: 3em !important;
    }
  }

	h1, h2, h3, h4, h5, h6, p, a {
		font-weight: 400 !important;
		font-family: brandon-grotesque, sans-serif !important;
	}
}
