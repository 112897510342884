@import '../colors.scss';

.amount-form {
  border: 1px solid $primary-color;

  button {
    padding-right: 0px;
    padding-left: 0px;
  }

  .MuiTextField-root {
    max-width: 3.5rem;
    margin: 0px;
  }

  .MuiTextField-root .MuiOutlinedInput-root fieldset {
    border-top: 0px;
    border-bottom: 0px;
    border-radius: 0px
  }

  & .MuiTextField-root .MuiOutlinedInput-root .MuiInputBase-input {
    padding: 8px
  }

	input {
		text-align: center;
	}

	fieldset {
		border-width: 0px !important;
		text-align: right;
	}

	.MuiButton-root {
		min-width: 2.5em !important;
	} 
}
