@import '../../common/components/colors.scss';

.product-view {
  .tabs-container {
    margin: 3em auto 6em auto;
    min-height: 20em;
  }

	.description {
    height: 72px;
    line-height: 24px;
    overflow: hidden;
	}

	h2 {
		margin-top: 2em !important;
		color: $secondary-color;
      font-size: 1.5em !important;
      letter-spacing: .25px !important;
      font-weight: 700 !important;
	}
}
