@import './../colors.scss';
@import url("https://use.typekit.net/ihm4eod.css");

.footer-wrap {
	padding-bottom: 2.5rem; 

	.footer {
		width: 100%;
		bottom: 0;
		position: absolute;
 		height: 2.5rem; 

		.container {
			margin: auto;
		}

		.details-container {
			margin-bottom: 40px !important;
		}

		.trademark {
			margin: 32px auto !important;
		}

		h1, h2, h3, h4, h5, h6, p, a {
			font-family: brandon-grotesque, sans-serif !important;
		}
	}
}