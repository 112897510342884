@import '../colors.scss';

.page-title {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin: 1.2em 0px 2.3em !important;
  position: relative;
  z-index: 1;
  color: $secondary-color !important;

  &::before, &::after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    color: 'rgba(0; 0; 0; 0)';
    background-color: $primary-color;
  }

  &::before {
    margin-right: 10px;
    margin-left: -50%;
    text-align: right;
  }
}
