@import './../../common/components/colors';

.cart-page {
	margin-bottom: 100px;
  img {
    max-width: 100px;
  }

  .contents {
		min-height: 300px;
    padding: 25px 0px;
    border-bottom: 1px solid rgba($grey-dark-color, .2);
    border-top: 1px solid rgba($grey-dark-color, .2);
    margin-bottom: 20px;
  }

	.sum-table,
	.format-order {
		float: right;
		width: 400px;
	}

  button {
  	border: 0px;
  }

	td {
		white-space: nowrap;
	}
}