$white-color: #FFFFFF;

$primary-color: #01052C;
$secondary-color: #A61C32;

$error-color: #f44336;
$warning-color: #ff9800;
$info-color: #2196f3;
$success-color: #4caf50;
$black-color: #000000;
$grey-dark-color: #2E2F30;
