@import './../../common/components/colors.scss';

.filter-amount {
  color: rgba($grey-dark-color, 0.6)
}

@media (max-width:599px) {
  .product-search {
    display: none !important;
  }
}